<template>
  <v-main>
    <div class="w-100 d-flex mt-8 align-center flex-column">
      <img src="@/assets/images/fli_logo.png" class="welcome-image mb-7" alt="" srcset="" @click="$router.push('/')">
      <router-view></router-view>
    </div>
    <!-- <v-footer>
      <p>Copyright Fastlog 2020 - <router-link to="/">fastlog.id</router-link> </p>
    </v-footer> -->
  </v-main>
</template>

<script>

export default {
  name: 'welcome-screen',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.v-main{
background-image: url('~@/assets/images/bg-login.png');
background-size: 1366px 547px;
background-position: bottom;
background-color: $--color-bg-alabaster;
overflow: hidden;
  .welcome {
    &-image {
      cursor: pointer;
      width: 229px;
      height: 70px;
    }
  }
  .v-footer {
    position: absolute;
    background: none;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
}
</style>
